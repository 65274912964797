import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const ZapisObuchayushchihVideo = () => {
  return (
    <>
      <Helmet>
        <title>Запись обучающих видео цена в Москве от студии Elandic</title>
        <meta name="description" content="Запись обучающих видео цена в Москве услуги под ключ от студии Elandic. Заказать услуги съемки обучающих роликов для бизнеса стоимость от видеопродакшена." />
        <meta name="keywords" content="запись обучающих видео, съемки, роликов, цена" />
      </Helmet>
      <MainPage>
        <SeoText title='Запись обучающих видео'>
          <p>Хотите повысить вовлеченность клиентов, увеличить продажи и упростить обучение сотрудников? Обучающие видео – это эффективный инструмент, который поможет вам достичь этих целей.</p>

          <p>Elandic – видеопродакшн в Москве, который специализируется на создании профессиональных обучающих видеороликов для бизнеса. Мы поможем вам разработать концепцию, снять качественное видео и смонтировать его в соответствии с вашими целями и требованиями.</p>

          <p>Мы работаем с бизнесом разных масштабов и отраслей, создавая обучающие видеоролики, которые не только информативны, но и привлекательны для аудитории. Предлагаем полный цикл услуг: от разработки сценария и создания сториборда до монтажа, озвучки и распространения видео. Используем профессиональную технику и программное обеспечение, чтобы обеспечить высокое качество видеосъемки и монтажа.</p>

          <p>Мы учитываем ваши потребности и цели, чтобы создать видео, которое будет максимально эффективным для вашего бизнеса. Предлагаем конкурентные цены на создание обучающих видео, которые доступны для предприятий любого размера.</p>

          <p>Создайте эффективные и запоминающиеся видеоуроки по различным темам: внутренним регламентам, использованию программного обеспечения, новому продукту и т.д. Детально расскажите о своей продукции с помощью качественных видеороликов, уделяя внимание преимуществам, функциональности и применению, видео с ответами на часто задаваемые вопросы, инструкции по использованию продукта, видеообзоры и другие информативные материалы. </p>

          <p>Стоимость съемки обучающих видео зависит от множества факторов: продолжительность видео, сложность съемки, необходимость специальных эффектов, использование дополнительного оборудования.</p>

          <p>Чтобы узнать точную стоимость съемки обучающего видео для вашего бизнеса, свяжитесь с нами для бесплатной консультации. Мы рады ответить на все ваши вопросы и помочь вам создать эффективное обучающее видео, которое поможет вашему бизнесу расти.</p>

          <p>Elandic – ваш надежный партнер в создании обучающих видео! Закажите съемку обучающего видео сегодня и получите бесплатную консультацию от наших специалистов.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(ZapisObuchayushchihVideo);
